<template>
  <div class="ma-4 pa-4">
    <v-row>
      <v-col>
        <span>Numéro</span></v-col>
      <v-col>
        <span class="font-weight-bold">{{ area.are_ug_num || '-' }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span>Identifiant</span></v-col>
      <v-col>
        <span class="font-weight-bold">{{ area.are_identifier || '-' }}</span>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col>
        <span>Couleur</span></v-col>
      <v-col>
        <span class="font-weight-bold">{{ area.are_color || '-' }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span>Nom</span></v-col>
      <v-col>
        <span class="font-weight-bold">{{ area.are_label || '-' }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span>Surface</span></v-col>
      <v-col>
        <span class="font-weight-bold">{{ (Math.round(area.are_ug_surface * 10 ) / 10).toFixed(0) || '-' }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span>Ordre</span></v-col>
      <v-col>
        <span class="font-weight-bold">{{ area.are_ug_order || '-' }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    area: {
      type: Object,
      required: true
    }
  }
}
</script>
