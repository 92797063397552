<template>
  <div>
    <v-app-bar
      dense
      dark
      color="#0A2A35"
    >
      <v-toolbar-title>Demander une nouvelle tâche</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <div>
      <v-sheet>
        <TaskFormDetails
          :custom="true"
          @save="saveTaskDetails"
          @close="close"
        />
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TaskFormDetails from '@/components/tasks/TaskFormDetails'

export default {
  components: { TaskFormDetails },
  props: {
    areaId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      valid: false,
      tab: 0
    }
  },
  methods: {
    async saveTaskDetails (task) {
      try {
        this.loading = true
        const data = await this.createTask({ are_id: this.areaId, ...task })
        this.$emit('added', data)
        this.close()
      } catch (err) {
        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: 'error' })
      } finally {
        this.loading = false
      }
    },
    close () {
      this.$emit('close')
    },
    ...mapActions({
      createTask: 'tasks/createTask'
    })
  }
}
</script>
