<template>
  <div style="height: 100%; position: relative; overflow:hidden;">
    <div id="map-container" style="height: 100%;"></div>
    <v-alert ref="liveinfo" :value="liveInfoState" color="primary" dark icon="mdi-information"  
        style="top: 10px; right: 250px; z-index: 999; position: absolute;">
    {{liveInfo}}
    </v-alert>
    <v-menu bottom left style="z-index:1050;">
      <template v-slot:activator="{ on }">
        <v-btn fixed dark fab bottom right color="orange" style="opacity: 0.80; top:80px; z-index:900;" elevation-1
              v-on="on" title="Changer le fond de carte">
          <v-icon>mdi-layers</v-icon>
        </v-btn>
      </template>
      <v-list dense class="rounded-lg">
        <template v-for="(item, i) in layers">
          <v-list-item :key="i" @click="changeTileLayer(item)">
            <v-list-item-action>
              <img :src="item.thumbnail" :alt="item.text" width="65" class="rounded-lg" :style="(currentLayer == item) ? 'border: 3px solid orange;' : ''" />
            </v-list-item-action>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <v-btn 
        fixed dark fab bottom right :color="(addAlertState) ? 'red' : 'grey'" style="opacity: 0.80; top:150px; z-index:900;" 
        elevation-1 @click="activeAlert(!addAlertState)" title="Créer une alerte">
        <v-icon>mdi-alert</v-icon>
    </v-btn>
    <v-menu v-model="layerMenu" :close-on-content-click="false" bottom offset-y :max-height="550" style="z-index:900;">
        <template v-slot:activator="{ on }">
            <v-btn title="Couches SIG" color="primary" v-on="on" fixed dark fab bottom right style="opacity: .80; top:260px; z-index:900;">
                <v-icon>mdi-layers</v-icon>
                <v-badge v-show="activeLayers" :color="(activeLayers == 0) ? 'red' : 'green'"><span slot="badge">{{ activeLayers }}</span></v-badge>
            </v-btn>
        </template>
        <v-app-bar color="primary" dense dark>
          <v-toolbar-title>Afficher / cacher des zones</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon title="Re-calculer les barycentres" @click="updateDistrictBound()"><v-icon>mdi-refresh</v-icon></v-btn>
        </v-app-bar>
        <v-list>
        <v-list-item v-for="(item, index) in listArea" :key="index" >
            <v-list-item-action @click="showHideLayer(item.are_identifier)" class="mr-1">
                <v-icon :color="(item.are_active) ? 'green' : 'gray'" style="cursor:pointer;">mdi-eye</v-icon>
            </v-list-item-action>
            <v-list-item-action @click="selectLayerColor(index)" class="mr-1">
                <v-icon :color="item.are_color" style="cursor:pointer;">mdi-palette</v-icon>
            </v-list-item-action>
            <!-- <v-list-item-action @click="editLayer(index)" class="mr-1">
                <v-icon :color="(item.edit)  ? 'green' : 'gray'" style="cursor:pointer;">mdi-pencil-outline</v-icon>
            </v-list-item-action> -->
            <v-list-item-action @click="fitToLayer(index)" class="mr-1">
                <v-icon color="grey" style="cursor:pointer;">mdi-crosshairs-gps</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.are_ug_name }}</v-list-item-title>
        </v-list-item>
        </v-list>
    </v-menu>

    <!-- Selection de couleurs -->
    <v-dialog v-if="colorDialog" v-model="colorDialog" hide-overlay max-width="600px" style="z-index:1051;">
    <v-card>
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Choisir la couleur du calque :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="colorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
            <v-color-picker @input="saveLayerColor" :value="pickerColor" class="ma-2 elevation-0"
                hide-inputs hide-canvas mode="hexa" ></v-color-picker> 
        </v-card-text>
    </v-card>
    </v-dialog>

    <v-btn fixed dark fab bottom right elevation-1 :color="showDistricts ? 'green' : 'grey'" style="opacity: 0.80; z-index:1000;"
      title="Afficher les arrondissements" @click="toggleDistricts()">
      <v-icon>mdi-map-outline</v-icon>
    </v-btn>
    <v-tooltip left style="z-index:1051;">
        <template v-slot:activator="{ on }">
            <v-btn @click="pdfDiag = true" fixed dark fab bottom right color="primary" style="opacity: .50; bottom:110px; z-index:900;" elevation-1 v-on="on">
                <v-icon>mdi-file-export</v-icon>
            </v-btn>
        </template>
        <span>Télécharger la carte</span>
    </v-tooltip>
    <v-navigation-drawer v-model="areaSliderOpen" hide-overlay right fixed :width="fullWidth ? '100vw' : '60vw'" style="z-index: 1000">
      <AreaSheet
        v-if="area"
        :area="area"
        @resize="toggleWidth"
        @closeArea="close"
        @added="onCreate"
      />
    </v-navigation-drawer>
    <Alert
      :show="success"
      fixed
      :duration="2000"
      variant="success"
      text="La tâche a été créée avec succès."
      @close="success = false"
    />
    <!-- ------------Alerte view ----------------- -->
    <v-dialog v-model="modalEvent" max-width="900" style="z-index:2066;">
        <v-card min-width="350px" flat class="rounded-lg">
        <v-toolbar color="primary" dark flat height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>{{selectionEvent.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="elevation-0 pa-0">
            <EditEvent
                v-if="modalEvent" :switchModal="switchModal"
                :xxxId="selectionEvent.xxxId" :eveId="selectionEvent.eveId" 
                :eveTable="selectionEvent.eveTable" :eveType="selectionEvent.eveType" 
                :title="selectionEvent.title" :cooridnates="selectionEvent.cooridnates" />
        </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Dialog export PDF -->
    <v-dialog v-model="pdfDiag" width="500" style="z-index:2066;">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Options du document
        </v-card-title>
        <v-card-text>
            <v-layout row wrap>
            <v-flex xs12><v-text-field v-model="pdfOpt.title" label="Titre"></v-text-field></v-flex>
            <v-flex xs12><v-text-field v-model="pdfOpt.comment" label="Commentaires"></v-text-field></v-flex>
            </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="pdfloading" text @click="getReport()" >Télécharger</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LfMap from '@/services/map'
import layers from '@/services/layers.map'
import { bus } from '@/plugins/bus'
import { mapGetters } from 'vuex' //mapActions
import AreaSheet from '@/components/map/AreaSheet'
import Alert from '@/components/ui/Alert'
import EditEvent  from '@/components/events/EventForm.vue'
import { districtUpdateService, filterUpdateService } from '@/rxjsServices';

let mapObject = { map: {} }

export default {
  components: {
    AreaSheet,
    Alert,
    EditEvent
  },
  data: function () {
    return {
      layers: layers,
      currentLayer: layers['pic'],
      showDistricts: true,
      projection: 'EPSG3857',
      areaSliderOpen: false,
      area: null,
      fullWidth: false,
      tasks: [],
      success: false,
      addAlertState: false, modalEvent: false, loadAlert: false,
      selectionEvent: { xxxId: 0, eveId:0, eveTable: "alt", eveType: "ALT", title:"Alerte", cooridnates:null },
      districts: [],
      districLoaded: false,
      pdfDiag: false,
      pdfloading: false,
      pdfOpt: { title:'', comment:'', logo:'logo_lisea', type:'Format Pdf' },
      listArea: {}, layerMenu: false, colorDialog: false, pickerColor : null,
      currentLayerEdit: null,
      liveInfo: '', liveInfoState: false,
    }
  },
  computed: {
    ...mapGetters({
      typeState: 'app/typesById',
      stateState: 'app/statesById',
      filterToUrl: 'app/filterToUrl',
      filterToPost: 'app/filterToPost',
    //  districts: 'map/districts'
    }),
    activeLayers: function(){
        var totalActive = 0
        for( var prop in this.listArea ){
            if( this.listArea[prop].are_active ){
                totalActive += 1
            }
        }
        return totalActive
    }
  },
  watch: {
    //tasks: { async handler () { await this.loadData() }, immediate: true }
  },
  async mounted () {
    mapObject.map           = new LfMap('map-container')
    mapObject.map.viewAlert = this.viewAlert
    mapObject.map.display([48.76846082, 2.29591343], 15, this.projection)

    await this.loadTasks()

    await this.toggleDistricts(true, true)
    await this.displayAlert()
    
    bus.$emit('layers:change', this.currentLayer.link)
    bus.$on('liveinfo:update', (obj) => this.updateLiveInfo(obj))
    bus.$on('liveinfo:close', (obj) => this.closeLiveInfo(obj))
    bus.$on('area:click', (obj) => this.showAreaSheet(obj))
    this.filterService = filterUpdateService.getFilter().subscribe(message => {
        if (message) {
          this.loadTasks().then( () => {
            this.toggleDistricts(false, false).then( () => {
              this.toggleDistricts(true, false)
              mapObject.map.udpateLayerStyle( this.alertArea )
            })
          })
        }
    })
    if( this.$route.params.latlng !== undefined && this.$route.params.latlng.length > 0 ){
        mapObject.map.mapCenter = this.$route.params.latlng.split(',')
        if( mapObject.map.mapCenter[0] !== 'null' && mapObject.map.mapCenter[1] ){
          mapObject.map.centerTo()
        }
    }
  },
  methods: {
    onCreate () {
      this.success = true
    },
    toggleWidth () {
      this.fullWidth = !this.fullWidth
    },
    showAreaSheet (area) {
      this.areaSliderOpen = true
      this.area = area
    },
    close () {
      this.areaSliderOpen = false
    },
    loadDistricts() {
      return new Promise( (resolve) => {
        this.loadDistrictsBash()
        this.districtService = districtUpdateService.getDistrict().subscribe(message => {
            if (message) {
                clearTimeout(this.districLoaded)
                resolve('resolved')
            }
        })
        this.districLoaded = setTimeout(() => {
          resolve('resolved')
        }, 5000)
      })
    },
    async loadDistrictsBash(page) {
      return new Promise( (resolve, reject) => {
        page = page || 1
        this.loading = true //await this.fetchDistricts()
        this.$http.get( '/areas?per_page=200&page=' + page ).then( (response) => {
          response.data.forEach(element => {
            if( !this.listArea[ element.are_identifier ] ){
              this.$set(this.listArea, element.are_identifier, { 
                are_identifier: element.are_identifier, are_active : true, are_ug_name: element.are_ug_name, are_color: element.are_color
              })
            }

            bus.$emit('districts:toggle', { show: true, districts: element })
          })
          this.districts.push(...response.data)
          if( response.meta.current_page < response.meta.last_page ){
            page++
            this.loadDistrictsBash(page)
          } else {
            districtUpdateService.sendDistrict('loaded')
            resolve('resolved')
          }
        }).catch( (err) => {
          this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: err })
          reject(err)
        })
        this.loading = false
      })
    },
    loadTasks(){
      return new Promise( (resolve, reject) => {
        let filter = (this.filterToUrl.length) ? this.filterToUrl + '&' : '?'
        filter    += 'group_by=tsk_are_id&order_by=tsk_sta_id&order_direction=asc&extended=withtasktype&include=area&min_tsk_are_id=1' //&extended=withtasktype
        this.$http.get( '/tasks/' + filter ).then( (response) => {
          response.data.forEach( (elem) => {
            elem.typ_color = (this.typeState[elem.tkt_typ_id].typ_color) ? this.typeState[elem.tkt_typ_id].typ_color : '#757575'
            elem.sta_color = (this.stateState[elem.tsk_sta_id].sta_color) ? this.stateState[elem.tsk_sta_id].sta_color : '#757575'
          })
          this.tasks = response.data
          resolve('resolved')
        }).catch( (err) => {
            this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: err })
            reject(err)
        })
      })
    },
    async toggleDistricts (show = null, refresh = false) {
      if (show === null) {
        this.showDistricts = !this.showDistricts
      } else {
        this.showDistricts = show
      }
      if (refresh || !this.districts) {
        await this.loadDistricts()
      }
      if( this.districts ){
        this.districts.forEach(element => {
            bus.$emit('districts:toggle', { show: this.showDistricts, districts: element })
        })
        for( let prop in this.listArea ){
          this.listArea[prop].are_active = this.showDistricts
        }
      }
      if( this.showDistricts ){
        bus.$emit('tasks:change', this.tasks)
      }
    },
    changeTileLayer(layer) {
      this.currentLayer = layer
      bus.$emit('layers:change', this.currentLayer.link)
    },
    //...mapActions({
    //  fetchDistricts: 'map/fetchDistricts'
    //}),
    activeAlert(state){
        this.addAlertState = state
        mapObject.map.activeAlert(state)
    },
    switchModal(name, mode){
        name    = name || 'dialogObj'
        mode    = mode || false
        this[name] = mode
        if( name == 'modalEvent' && !mode ){
            mapObject.map.removeAllAlert()
            this.loadAlert = false
            this.displayAlert()
        }
    },
    displayAlert(){
      return new Promise( (resolve, reject) => {
        this.loadAlert = !this.loadAlert
        this.alertArea  = []
        if( this.loadAlert ){
            this.$http.get( '/events/?eve_sta_id=GEN01,GEN04&eve_typ_id=%ALT%&eve_table_id=alt,are&per_page=false' )
            .then( (response) => {
                var kp, vp
                for (kp = 0; (vp = response.data[kp]) !== undefined; kp++) {
                    if( vp.eve_lat && vp.eve_lng && vp.eve_table_id == 'alt' ){
                      mapObject.map.addAlert( vp )
                    } else if( vp.eve_table_id == 'are' ) {
                      this.alertArea.push( vp.eve_xxx_id )
                    }
                }
                mapObject.map.udpateLayerStyle( this.alertArea )
                resolve('resolved')
            }).catch( (error) => { 
                reject(error)
            })
        } else {
            mapObject.map.removeAllAlert()
        }
      })
    },
    viewAlert( altObj ){
        this.selectionEvent.xxxId    = 0
        this.selectionEvent.eveId    = altObj.eve_id
        this.selectionEvent.eveTable = 'alt'
        this.selectionEvent.eveType  = "ALT"
        this.selectionEvent.title    = 'Alerte'
        this.selectionEvent.cooridnates    = { lat: altObj.eve_lat, lng: altObj.eve_lng }
        //this.alertDialog = true
        this.switchModal('modalEvent', 1)
    },
    async getReport(){
        this.pdfloading = true
        //let filter      = (this.filterToUrl.length) ? this.filterToUrl + '&' : '?'
        let filter       = this.filterToPost
        filter['include']= 'area,types'
        let filename     = 'rapport.pdf'
        let img = await mapObject.map.getMapImage()

        var form = { 
            method: 'post', url: '/report/mapextraction/', responseType: 'blob', 
            data: { filter: filter, tmpimage: img, pdfoption: this.pdfOpt }
        }
        this.$http.customRequest(form).then( (response) => {
            this.pdfloading = false
            this.pdfDiag = false
            this.$download(response.data, filename, response.headers['content-type'])
        }).catch( (error) => { 
            this.pdfloading = false
            this.pdfDiag = false
            this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: error })
        })
    },
    showHideLayer(id){
        this.listArea[id].are_active = !this.listArea[id].are_active
        mapObject.map.showHideLayer(id, this.listArea[id].are_active, this.districts)
    },
    selectLayerColor(id){
      this.currentLayerEdit = id
      this.pickerColor      = this.hexToRgbA( this.listArea[ id ].are_color )
      this.layerMenu        = false
      this.colorDialog      = true
    },
    saveLayerColor(color){
        var hexColor = "#" + ((1 << 24) + (color.r << 16) + (color.g << 8) + color.b).toString(16).slice(1)
        this.listArea[ this.currentLayerEdit ].are_color = hexColor
        mapObject.map.udpateAreaStyle(this.currentLayerEdit, hexColor, color.a)
    },
    hexToRgbA(hex){
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            a: 1
        } : null;
    },
    updateDistrictBound(){
      let districts = []
      this.districts.forEach( (item) => {
        let center = mapObject.map.getCenter(item)
        districts.push( { are_id: item.are_id, are_lat: center.lat, are_lng: center.lng } )
      })
      this.$http.post( '/areas/massupdate', {areas : districts} ).then( () => {
        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text: 'Mise à jour terminée !' })
      })
    },
    updateLiveInfo(obj){
      this.liveInfoState = true
      this.liveInfo = '#' + obj.are_id + ' ' +  obj.are_ug_name
    },
    closeLiveInfo(){
      this.liveInfoState = false
    }
  },
  beforeDestroy() {
    //bus.$off()
    this.districtService.unsubscribe()
    this.filterService.unsubscribe()
  }
}
</script>

<style lang="scss">
@import "../../node_modules/leaflet/dist/leaflet.css";
@import '../assets/leaflet.pm.css';
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
@import '../assets/leaflet.css';

.dealInfo {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  z-index: 500;
  max-width: 250px;
  max-height: 80%;
}

.leaflet-pane {
  z-index: 0;
}

.v-overlay--active {
  z-index: 1053 !important;
}

.v-dialog__content--active {
  z-index: 1055 !important;
}

.bottnavdraw {
  position: fixed;
}

.v-btn.active .v-icon {
  transform: rotate(-45deg);
}
</style>
