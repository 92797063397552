<template>
  <div>
    <v-app-bar
      dense
      dark
      color="#0A2A35"
    >
      <v-toolbar-title>Nouvelle tâche</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <div>
      <v-sheet>
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab class="text-uppercase">Informations</v-tab>
          <v-tab class="text-uppercase" :disabled="!task">Agents</v-tab>
          <v-tab class="text-uppercase" :disabled="!task">Temps</v-tab>
          <!-- <v-tab class="text-uppercase" :disabled="!task">Fichiers</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <TaskFormDetails
              @save="saveTaskDetails"
              @close="close"
            />
          </v-tab-item>
          <v-tab-item>
            <TaskFormUsers v-if="task" :task="getEditableTask(task)" @save="onSave" />
          </v-tab-item>
          <v-tab-item>
            <TaskFormTime v-if="task" :task="getEditableTask(task)" @save="onSave" />
          </v-tab-item>
          <!-- v-tab-item>Fichiers<pre>{{ task }}</pre></v-tab-item> -->
        </v-tabs-items>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TaskFormDetails from '@/components/tasks/TaskFormDetails'
import TaskFormUsers from '@/components/tasks/TaskFormUsers'
import TaskFormTime from '@/components/tasks/TaskFormTime'
import TaskMixin from '@/mixins/TaskMixin'

export default {
  mixins: [TaskMixin],
  components: { TaskFormTime, TaskFormUsers, TaskFormDetails },
  props: {
    areaId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      tab: 0,
      task: null
    }
  },
  methods: {
    async saveTaskDetails (task) {
      try {
        this.loading = true
        this.task = await this.createTask({ are_id: this.areaId, ...task })
        this.$emit('added', this.task)
        this.tab++
      } catch (err) {
        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: 'error' })
      } finally {
        this.loading = false
      }
    },
    async onSave (task) {
      try {
        this.loading = true
        const payload = this.getTaskPayload(task)
        const data = await this.updateTask(payload)
        this.$emit('updated', data)
        this.task = await this.fetchTask(task.id)
      } catch (err) {
        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: err })
      } finally {
        this.loading = false
      }
      this.$emit('saved')
      if( this.tab > 1 ){
        this.close()
      }
      this.tab++
    },
    close () {
      this.$emit('close')
    },
    ...mapActions({
      createTask: 'tasks/createTask',
      updateTask: 'tasks/updateTask',
    })
  }
}
</script>
