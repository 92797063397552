import L from 'leaflet';
// eslint-disable-next-line no-unused-vars
import MarkerCluster from 'leaflet.markercluster'
// eslint-disable-next-line no-unused-vars
import LeafleatPm from 'leaflet.pm';
import { bus } from '@/plugins/bus'
import markerFn from '@/services/marker'
import vuetify from '@/plugins/vuetify'
import leafletImage from 'leaflet-image'

class LfMap {
  constructor(mapContainer) {
    this.layerUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
    this.mapContainer = mapContainer;
    this.baseLayerMap = this.setLayer('notile'); //this.offlineLayerMap = this.setLayer('offline');
    this.Map = null;
    this.arrayOfLatLngs = [];
    this.leaflet = window.L;
    this.defautLatLng = [47.47479783478821, 1.0530929565429689];
    this.markerLoc = [0, 0];
    this.markerDrag = null;
    this.circleMarker = null;
    this.partMarkers = L.layerGroup();
    this.svgIcon = {
      green: '<div class="marker-svg marker-green"><center></center></div>',
      blue: '<div class="marker-svg marker-blue"><center></center></div>',
      orange: '<div class="marker-svg marker-orange"><center></center></div>',
      red: '<div class="marker-svg marker-red"><center></center></div>',
      truck: '<div class="marker-svg marker-truck"><center></center></div>',
      gen: '<div class="marker-svg marker-gen"><center></center></div>'
    };
    /************* LAYERS *************/
    this.baseLayer = {};
    this.layerArea     = {};
    this.layerDistrict = L.layerGroup();
    this.layerDraw     = L.layerGroup();
    this.layerAlert    = L.layerGroup();
    this.layerTree     = L.layerGroup();
    this.layerTask     = L.layerGroup();
    this.layerDistrictsMarkers = L.layerGroup();
    this.clusterTree = L.markerClusterGroup({ disableClusteringAtZoom: 16 });
    this.clusterTask = L.markerClusterGroup({ disableClusteringAtZoom: 18 });
    /************* BLOCKS *************/
    this.blocks = { origin: {}, generic: {}, default: {} }
    /************* GEOJSON *************/
    this.geoJson = {};
    /************* ******* *************/
    this.vehicleTo = undefined;
    this.zoomToGetTree = function () {
      alert('Action non paramétré')
    };
    this.setObjState = function () {
      alert('Action non paramétré')
    };
    this.objAction = function () {
      alert('Action non paramétré')
    };
    this.viewAlert = function () {
      alert('Action non paramétré')
    };
    this.showObjectCard = function () {
      alert('Action non paramétré')
    };
    this.showSelectionCard = function () {
      alert('Action non paramétré')
    };
    /************* ******* *************/
    this.modeAlert = false;
    this.mapCenter = null;
    this.zoom = 6;
    this.currentBound = null;
    this.taskMarker = {};
    this.tasks = [];
    this.disctricsById = {};

    this.listenEvents()
  }

  listenEvents () {
    bus.$on('map:init', (obj) => this.initMap(obj))
    bus.$on('districts:toggle', (obj) => this.toggleDistricts(obj))
    bus.$on('layers:change', (obj) => this.setTileLayer(obj))
    bus.$on('off', (obj) => this.stopListeningEvents(obj))
    bus.$on('tasks:change', (obj) => this.setTask(obj))
  }

  /**
   * EVENTS
   */

  stopListeningEvents () {
    bus.$off()
  }

  initMap ({ latlng, zoom, projection }) {
    this.display(latlng, zoom, projection)
  }

  toggleDistricts ({ show, districts }) {
    this.displayDistricts(districts, show)
    if( !show ){
      this.clusterTask.eachLayer( layer => {
        this.clusterTask.removeLayer(layer)
      })
    } //else { this.showDistrictsMarkers() }
  }

  setTask( tasks ){
    this.tasks = tasks
    this.addTaskMarkers()
  }

  showDistrictsMarkers () {
    this.addTaskMarkers()
  }

  /**
   * NEW INTERNALS
   */

  getDistrictCenterLatLng (bounds) {
    return bounds.getCenter()
  }

  buildDistrictMarker (district, layer) {
    const count = {
      nb: district?.meta?.counts?.scheduled,
      color: vuetify.userPreset.theme.themes.light.scheduled
    }
    if (district?.meta?.counts?.late) {
      count.nb = district?.meta?.counts?.late
      count.color = vuetify.userPreset.theme.themes.light.error
    }
    if (district?.meta?.counts?.soon) {
      count.nb = district?.meta?.counts?.soon
      count.color = vuetify.userPreset.theme.themes.light.warning
    }
    // We want all not finished tasks
    count.nb = district?.meta?.counts?.notFinished

    const divIcon = L.divIcon({
      html: markerFn(count),
      iconSize: [40, 40],
      iconAnchor: [20, 20],
    })
    const bounds = layer.getBounds()
    const marker = L.marker(this.getDistrictCenterLatLng(bounds), {
      icon: divIcon
    })
    marker.on('click', () => {
      bus.$emit('area:click', district)
    })
    this.clusterTask.addLayer(marker)
  }

  addTaskMarkers () {
    this.tasks.forEach( (task) => {
      if( task.area ){
        let area = {}
        if( typeof task.area.are_geojson_feature === 'object' ){
          area = task.area.are_geojson_feature
        } else {
          area = JSON.parse(task.area.are_geojson_feature)
        }
        let layer= L.geoJson(area)

        const divIcon = L.divIcon({
          html: markerFn({nb:task.tsk_id_count, color: task.sta_color}), //vuetify.userPreset.theme.themes.light.scheduled
          iconSize: [40, 40],
          iconAnchor: [20, 20],
        })
        const bounds = layer.getBounds()
        const marker = L.marker(this.getDistrictCenterLatLng(bounds), {
          icon: divIcon
        })
        marker.on('click', () => {
          bus.$emit('area:click', task.area)
        })
        this.clusterTask.addLayer(marker)
        if( this.disctricsById['area_' + task.tsk_are_id] ){
          this.disctricsById['area_' + task.tsk_are_id].setStyle( { weight: 2, fillOpacity: 0.6, fillColor: task.sta_color, opacity: 1, color: task.sta_color } )
        }
      }
    })
  }

  addDistrictsMarkers (districts) {
    this.layerDistrictsMarkers = L.geoJson(districts.are_geojson_feature, {
      onEachFeature: (district, layer) => {
        if( layer.feature.meta && layer.feature.meta.tasks && layer.feature.meta.tasks.length ){
          this.buildDistrictMarker(district, layer)
        }
      },
    })
  }


  /**
   * INTERNAL
   */

  display(latlng, zoom, projection) {
    latlng = latlng || this.defautLatLng;
    zoom = zoom || 6;
    this.zoom = zoom;
    projection = projection || 'EPSG3857';
    this.projection = projection;
    if (projection === 'Simple') {
      // Hack for Circle
      L.LatLng.prototype.distanceTo = function (other) {
        var dx = other.lng - this.lng;
        var dy = other.lat - this.lat;
        return Math.sqrt(dx * dx + dy * dy);
      }
    }
    var mapOptions = {
      zoom: zoom,
      zoomControl: false,
      center: latlng,
      crs: L.CRS[projection],
      preferCanvas: true,
      tapTolerance: 20,
      maxZoom: 28, minZoom: -20, maxNativeZoom: 16,
      layers: [ this.baseLayerMap ],
    };

    if (latlng !== undefined) {
      mapOptions.center = latlng
    }
    if (zoom !== undefined) {
      mapOptions.zoom = zoom
    }
    // Init MAP
    this.Map = new this.leaflet.map(this.mapContainer, mapOptions);

    this.layerDistrict.addTo(this.Map);
    this.layerDraw.addTo(this.Map);
    this.clusterTask.addTo(this.Map);
    this.clusterTree.addTo(this.Map);

    /************ Action Dessin **********************/
    /*
    this.Map.on('pm:create', (e) => {
      setTimeout(() => {
        this.Map.removeLayer(e.layer);
        this.showSelectionCard(e.layer.getBounds());
      }, 600)
    });
     */
    /************ Action sur zoom carte **********************/
    /*
    this.Map.on('moveend', () => {
      //this.currentBound = this.Map.getBounds();
      this.zoom = this.Map.getZoom();
      this.zoomToGetTree(this.zoom);
    });
    */
    /************ Action sur click carte **********************/
    this.layerTask.addTo(this.Map);
    this.layerAlert.addTo(this.Map);

    this.Map.on('click', (e) => {
      if (this.modeAlert) {
        this.addLocMarker({ lat: e.latlng.lat, lng: e.latlng.lng });
      }
    });

    if (Object.prototype.toString.call(this.mapCenter) === '[object Array]') {
      this.Map.setView(new L.LatLng(this.mapCenter[0], this.mapCenter[1]), 20);
    }
    L.control.scale({ imperial: false }).addTo(this.Map);
    return this.Map;
  }

  locateMe(markerState, mode) {
    mode = mode || false
    markerState = markerState || false;
    var self = this;

    if (markerState === true) {
      this.Map.locate({
        setView: true,
        watch: false,
        enableHighAccuracy: true
      }).on('locationfound', function (e) {
        self.focusOnUser(e);
        let marker = self.buildMarker({ draggable: false, type: 'blue', latLng: [e.latlng.lat, e.latlng.lng] })
        self.handle += 1;
        marker.feature = {}; // Initialize feature
        marker.feature.type = "Feature"; // Initialize feature.type
        marker.feature.properties = {}; // Initialize feature.properties
        marker.feature.geometry = {};
        marker.feature.properties.id = '0';
        marker.feature.properties.layer = self.currentLayer; //lraObj.lra_source_id;
        marker.feature.properties.handle = self.handle.toString(16).toUpperCase();
        marker.feature.properties.ownerHandle = self.ownerHandle;
        marker.feature.properties.color = (self.layerTable[self.currentLayer].color) ? self.layerTable[self.currentLayer].color : '#3388ff';
        marker.feature.properties.formType = self.formTab['Point'];
        marker.feature.geometry.type = 'Point';

        self.layerDraw.addLayer(marker);
        //self.drawState.active = true;
        self.setDrawSave(false);
      });
    } else if (mode) {
      this.Map.locate({
        setView: false,
        watch: true,
        enableHighAccuracy: true
      }).on('locationfound', function (e) {
        if (parseInt(e.accuracy) > 500) {
          self.focusOnUser({ latlng: { lat: 48.818854, lng: 2.319438 } }, 15);
        } else {
          self.focusOnUser(e);
        }
      });
    } else {
      this.Map.stopLocate();
    }
  }

  setLayer(value) {
    switch (value) {
      case 'base':
        return this._setBaseLayer();
      case 'offline':
        return this._setOfflineLayer();
      case 'notile':
        return this._setNoTileLayer();
      default:
        return this._setNoTileLayer();
    }
  }

  // Ajouter une zone (cercle)
  addCircleZone(pos) {
    if (pos.lat == 0 || pos.lng == 0 || pos.lat == undefined) {
      pos.lat = this.defautLatLng[0];
      pos.lng = this.defautLatLng[1];
    }
    if (this.circleMarker == null || this.circleMarker == undefined) {
      this.circleMarker = L.circle([pos[1], pos[0]], {
        color: '#2196F3',
        fillColor: '#2196F3',
        fillOpacity: 0.5,
        radius: 20
      }).addTo(this.Map);
    } else {
      this.circleMarker.setLatLng([pos[1], pos[0]]);
    }
    setTimeout(() => {
      this.Map.removeLayer(this.circleMarker)
    }, 1500);
  }

  panTo(pos, zoom) {
    zoom = zoom || 19;
    if (pos.lat !== undefined) {
      pos[1] = pos.lat;
      pos[0] = pos.lng;
    }
    this.Map.setView([pos[1], pos[0]], zoom, {
      pan: { animate: true, duration: 1.50 },
      zoom: { animate: true }
    });
  }

  // Ajout de taches existante
  addTask(taskObj) {
    let obj = {
      draggable: false,
      marker: 0,
      type: 'gen',
      color: taskObj.color,
      latLng: [taskObj.tre_lat, taskObj.tre_lng]
    }
    if (taskObj.tsk_date_due && taskObj.tsk_date_due.length > 4) {
      obj.marker = 'date_due'
    }
    if (taskObj.eve_total) {
      obj.marker = 'warning'
    }
    var marker = this.buildMarker(obj);
    var self = this;
    marker.on({
      click: function (e) {
        self.showObjectCard({ tsk_id: taskObj.tsk_id, tsk_lat: e.latlng.lat, tsk_lng: e.latlng.lng });
      }
    });

    this.clusterTask.addLayer(marker);
    this.taskMarker[taskObj.tsk_id] = marker //this.clusterTask.getLayerId(marker)
    //this.layerAlert.addLayer( marker );
    //this.layerAlert['alt' + alertObj.alt_id ] = marker.addTo(this.Map);
  }

  updateTask(taskObj) {
    let obj = {
      draggable: false,
      marker: 0,
      type: 'gen',
      color: taskObj.color,
      latLng: [taskObj.tre_lat, taskObj.tre_lng]
    }
    if (taskObj.tsk_date_due && taskObj.tsk_date_due.length > 4) {
      obj.marker = 'date_due'
    }
    if (taskObj.eve_total) {
      obj.marker = 'warning'
    }
    if (this.taskMarker[taskObj.tsk_id]) {
      let icon = this.buildIcon(obj)
      this.taskMarker[taskObj.tsk_id].setIcon(icon)
    } else {
      this.addTask(taskObj)
    }
  }

  // Ajout d'alerte existante
  addAlert(alertObj) {
    var marker = this.buildMarker( { draggable: false, type: 'orange-warning', latLng: [alertObj.eve_lat, alertObj.eve_lng] } );
    if( alertObj.eve_sta_id == 'GEN04' ){
      marker = this.buildMarker( { draggable: false, type: 'green-warning', latLng: [alertObj.eve_lat, alertObj.eve_lng] } );
    }
    var self   = this;
    marker.on({ 
      click: function(e) {
        self.viewAlert( { eve_id: alertObj.eve_id, eve_lat: e.latlng.lat, eve_lng: e.latlng.lng } );
        //self.viewAlert( { alt_id:alertObj.alt_id, alt_lat:e.latlng.lat, alt_lng:e.latlng.lng } );
      }
    });
    this.layerAlert.addLayer( marker );
  }

  removeAllAlert() {
    this.layerAlert.eachLayer(layer => {
      this.layerAlert.removeLayer(layer)
    })
  }

  // Ajouter un marker Alerte
  addAlertMarker(active) {
    var self = this;
    if (active) {
      this.Map.on('click', function (e) {
        self.addLocMarker(e.latlng);
      });
    } else {
      this.Map.off('click');
      if (this.markerDrag !== undefined && this.markerDrag !== null) {
        this.Map.removeLayer(this.markerDrag);
        this.markerDrag = null;
      }
    }
  }

  // Afficher les zones
  displayDistricts(geojson, show) {
    //const bounds = L.latLngBounds([])

    function onEachFeature(feature, layer) {
      // add feature to features collection
      // get the bounds of an individual feature
      //const layerBounds = layer.getBounds()
      if( layer.setStyle && layer.feature.geometry.type == 'LineString' || layer.feature.geometry.type == 'MultiLineString' ){
        layer.setStyle( { weight: 10, fillOpacity: 0.2, fillColor: "#607D8B", opacity: 1, color: "#607D8B" } )
      } else {
        layer.setStyle( { weight: 2, fillOpacity: 0.2, fillColor: "#808080", opacity: 1, color: "#0078CE" } )
      }
      layer.feature.area = geojson
      // extend the bounds of the collection to fit the bounds of the new feature
      //bounds.extend(layerBounds)
    }

    if (show) {
      this.disctricsById['area_' + geojson.are_id] = L.geoJson(geojson.are_geojson_feature, {
        pointToLayer: (feature, latlng) => {
          return L.circle(latlng, {
            pmIgnore: true,
            color: 'red',
            fillColor: 'red',
            fillOpacity: 1,
            radius: 2 //feature.properties.formRadius
          });
        },
        onEachFeature: onEachFeature
      }).addTo(this.layerDistrict)
      this.disctricsById['area_' + geojson.are_id]['_are_identifier'] = geojson.are_identifier
      // listen to events on areas/features
      this.disctricsById['area_' + geojson.are_id].on('click', (e) => {
        if (!this.modeAlert) {
          bus.$emit('area:click', e?.layer?.feature?.area)
        }
      })
      this.disctricsById['area_' + geojson.are_id].on('mouseover', () => {
        bus.$emit('liveinfo:update', geojson)
      });
      this.disctricsById['area_' + geojson.are_id].on('mouseout', () => {
        bus.$emit('liveinfo:close', geojson)
      });
      // set features bounds
      //this.setMapBoundsToFeaturesCollection(bounds)
    } else {
      this.layerDistrict.clearLayers()
    }

  }

  showHideLayer(identifier, show, district){
    if( show ){
      district.forEach( (item) => {
        this.displayDistricts(item, show)
      })
    } else {
      this.layerDistrict.eachLayer( (layer) => {
        if( layer._are_identifier == identifier && !show ){
          this.layerDistrict.removeLayer(layer)
        }
      })
    }
  }

  // Activer lr mode alert
  activeAlert(mode) {
    mode = mode || false
    this.modeAlert = mode;
  }

  udpateLayerStyle(items){
    items.forEach(element => {
      if( this.disctricsById['area_' + element] ){
        this.disctricsById['area_' + element].setStyle( { weight: 3, fillOpacity: 0.2, fillColor: "#607D8B", opacity: 1, color: "red" } )
      }
    });
  }

  udpateAreaStyle(id, color, percent){
    let opacity     = percent
    let fillOpacity = ( percent > 0 ) ? percent / 5 : 0
    this.layerDistrict.eachLayer( (layer) => {
      if( layer._are_identifier == id ){
        if( typeof layer.setStyle === "function" ){
          layer.setStyle( { color: color, fillColor :color, opacity: opacity, fillOpacity : fillOpacity } );
        }
      }
    })
  }

  // Ajouter un marqeur de géolocalisation
  addLocMarker(pos) {
    if (pos.lat == 0 || pos.lng == 0) {
      pos.lat = this.defautLatLng[0];
      pos.lng = this.defautLatLng[1];
    }
    if ((this.markerDrag == null || this.markerDrag == undefined) && !this.modeTree) {
      this.markerDrag = this.buildMarker({ draggable: true, type: 'orange-warning', latLng: [pos.lat, pos.lng] });
      //this.markerDrag.addTo(this.Map);
      this.layerAlert.addLayer(this.markerDrag);
      var self = this;
      // Open alert on click
      if (self.modeAlert) {
        this.markerDrag.on({
          click: function (e) {
            self.viewAlert({ eve_id: 0, eve_lat: e.latlng.lat, eve_lng: e.latlng.lng });
          }
        });
      }
    } else if (this.modeTree) {
      this.markerDrag = this.buildMarker({ draggable: true, type: 'blue', latLng: [pos.lat, pos.lng] });
      this.layerTask.addLayer(this.markerDrag);
      this.setDrawSave(false)
    } else {
      this.markerDrag.setLatLng(new this.leaflet.LatLng(pos.lat, pos.lng));
    }
    //this.Map.setView( new this.leaflet.LatLng(pos.lat, pos.lng), 9 );
  }

  buildMarker(obj) {
    let title = obj.title || '';
    let popup = obj.popup || '';
    let draggable = obj.draggable || false
    let marker = L.marker(obj.latLng, { //this.leaflet.marker(obj.latLng, {
      title: title,
      icon: this.buildIcon(obj),
      draggable: draggable
    });
    marker.on('dragend', () => {
      var position = marker.getLatLng();
      this.markerLoc = [position.lat, position.lng];
    });
    if (popup.length > 0) {
      marker.bindPopup(popup);
    }

    return marker;
  }

  buildIcon(obj) {
    let type = obj.type;
    let html = '<div class="marker-svg marker-blue"><center></center></div>';
    switch (type) {
      case 'blue':
        html = this.svgIcon['blue'];
        break;
      case 'green':
        html = this.svgIcon['green'];
        break;
      case 'orange':
        html = this.svgIcon['orange'];
        break;
      case 'red':
        html = this.svgIcon['red'];
        break;
      case 'truck':
        html = this.svgIcon['truck'];
        break;
      case 'gen':
        if (obj.color) {
          html = '<div><svg style="fill:' + obj.color + ';" xmlns="http://www.w3.org/2000/svg" viewBox="1239.2 -915.6 332.5 593">';
        } else {
          html = '<div><svg xmlns="http://www.w3.org/2000/svg" viewBox="1239.2 -915.6 332.5 593">';
        }
        html += '<path opacity=".2" d="M1544.6-459c-46.1-15.4-305.3-36.5-305.3 13.4v3.8c0 48 163.2 119.1 163.2 119.1 184.3-99.8 193.9-119 142.1-136.3z"/>';
        html += '<path d="M1398.6-339.9s-159.4-272.7-159.4-458.9 251.6-107.5 297.6-49.9c50 63.3 40.4 140.1-138.2 508.8z" />';
        html += '<path fill="#000" opacity=".3" d="M1398.6-339.9s-159.4-272.7-159.4-458.9 251.6-107.5 297.6-49.9c50 63.3 40.4 140.1-138.2 508.8z" />';
        html += '<path d="M1398.6-339.9s-159.4-265-159.4-447.4 234.3-105.6 276.5-49.9c48.1 61.4 55.7 97.9-117.1 497.3z" />';
        html += '<circle fill="#FFF" stroke="#33756E" stroke-width=".4" stroke-miterlimit="10" cx="1392.9" cy="-731.7" r="71" /></svg>';
        if (obj.marker) {
          html += '<img src="./' + obj.marker + '.svg" style="width: 25px; margin: 0 auto; position:absolute; top:4px; right:6px;">';
        }
        html += '<div>';
        break;
      default:
        var p = type.split("-");
        if (p[1] !== undefined) {
          html = '<div class="marker-svg marker-' + p[0] + '"><img src="./' + p[1] + '.svg" style="width: 25px; margin: 0 auto; position:absolute; top:4px; right:6px;"></div>';
        } else {
          html = this.svgIcon['blue'];
        }
    }

    return this.leaflet.divIcon({
      html: html,
      iconSize: [34, 56],
      iconAnchor: [17, 55],
      popupAnchor: [0, -56],
      className: 'marker-transp'
    });
  }

  _setBaseLayer() {
    return L.tileLayer('https://{s}.tiles.technosig.fr/' + 'hqVrnWZjNZnF8FL' + '/wmts/sceauxtiles/webmercator/{z}/{x}/{y}.png', { //this.layerUrl
      attribution: 'TECHNOSIG',
      maxNativeZoom: 18, maxZoom: 24,
      id: 'osm'
    });
  }

  _setOfflineLayer() {
    return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', { //this.layerUrl
      attribution: 'TECHNOSIG - Offline',
      id: 'osm cache'
    });
  }

  _setNoTileLayer() {
    return L.tileLayer('', { //this.layerUrl
      attribution: 'TECHNOSIG - Offline',
      id: 'osm cache'
    });
  }

  setTileLayer(link) {
    this.Map.removeLayer(this.baseLayerMap);
    this.baseLayerMap = L.tileLayer(link, {
      attribution: 'TECHNOSIG',
      id: 'osm',
      maxZoom: 28, minZoom: -20, maxNativeZoom: 18
    });
    this.Map.addLayer(this.baseLayerMap);
  }

  setInitialCenter() {
    this.Map.setView(this.defautLatLng)
    this.Map.setZoom(6)
  }

  setMapBoundsToFeaturesCollection(bounds) {
    // once we've looped through all the features, zoom the map
    this.Map.fitBounds(bounds)
  }

  getMapImage(){
    return new Promise( (resolve) => {
      if(this.Map.hasLayer(this.layerAlert)) { this.Map.removeLayer(this.layerAlert) }
      if(this.Map.hasLayer(this.clusterTask)) { this.Map.removeLayer(this.clusterTask) }
      leafletImage(this.Map, (err, canvas) => {
        var img  = canvas.toDataURL()
        if(!this.Map.hasLayer(this.layerAlert)) { this.Map.addLayer(this.layerAlert) }
        if(!this.Map.hasLayer(this.clusterTask)) { this.Map.addLayer(this.clusterTask) }
        resolve(img)
      })
    })
  }

  getCenter(geojson){
    let layer = L.geoJson(geojson.are_geojson_feature)
    return layer.getBounds().getCenter()
  }

  centerTo(){
    if( Object.prototype.toString.call( this.mapCenter ) === '[object Array]' ){
      this.Map.setView(new L.LatLng(this.mapCenter[0], this.mapCenter[1]), 20);
    }
  }
}

export default LfMap;
