export default {
  pic: {
    text: 'Fond de plan Dessin',
    thumbnail: require('@/assets/plan_sceaux.jpg'),
    link: 'https://{s}.tiles.technosig.fr/' + 'hqVrnWZjNZnF8FL' + '/wmts/sceauxtiles/webmercator/{z}/{x}/{y}.png',
    color: 'green',
    icon: 'mdi-check'
  },
  ign: {
    text: 'Fond de plan IGN',
    thumbnail: require('@/assets/plan_ign.jpg'),
    link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png',
    color: 'green',
    icon: 'mdi-check'
  },
  osm: {
    text: 'Fond de plan OSM',
    thumbnail: require('@/assets/plan_osm.jpg'),
    link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/arcgistiles/webmercator/{z}/{x}/{y}.png',
    color: 'green',
    icon: 'mdi-check'
  },
  bph: {
    text: 'Fond de plan photo',
    thumbnail: require('@/assets/plan_photo.jpg'),
    link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/igntiles/webmercator/{z}/{x}/{y}.png',
    color: 'green',
    icon: 'mdi-check'
  },
  bwt: {
    text: 'Sans fond',
    thumbnail: require('../assets/plan_sans.jpg'),
    link: '',
    color: 'green',
    icon: 'mdi-check'
  },
}
