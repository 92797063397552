<template>
  <div>
    <v-app-bar dense dark color="#0A2A35">
      <v-toolbar-title>{{ area.are_identifier }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="$emit('resize')">
        <v-icon>mdi-window-maximize</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon @click="$emit('closeArea')">
        <v-icon>mdi-close</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-sheet color="primary lighten-1" class="pa-4">
      <div class="flex align-center justify-end">
        <v-btn @click.prevent.stop="show" color="outline">
          Nouvelle tâche
        </v-btn>
      </div>
    </v-sheet>
    <v-container>
      <v-card elevation="3">
        <v-tabs v-model="tab" grow>
          <v-tab class="text-uppercase">Informations</v-tab>
          <v-tab class="text-uppercase">Tâches</v-tab>
          <v-tab class="text-uppercase">Alertes</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <AreaDetails :area="area" />
          </v-tab-item>
          <v-tab-item>
            <TasksList :tasks="applyFilterOnTasks(areaTasks)" />
          </v-tab-item>
          <v-tab-item>
            <EventTab v-if="tab === 2" :eve_xxx_id="area.are_id" :table_id="'are'" typfilter="ALT" title="Alertes" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <v-dialog
      v-model="open"
      width="600"
      persistent
      style="z-index:20002;"
    >
      <TaskCreateForm
        v-if="open && permissions.tasks.create"
        :area-id="area.are_id"
        @added="onCreate"
        @saved="loadData"
        @close="close"
      />
      <TaskCreateCustomForm
        v-if="open && permissions.tasks.createCustom"
        :area-id="area.are_id"
        @added="onCreate"
        @close="close"
      />
    </v-dialog>
  </div>
</template>

<script>
import TasksList from '@/components/tasks/TasksList'
import TaskCreateForm from '@/components/tasks/TaskCreateForm'
import TaskCreateCustomForm from '@/components/tasks/TaskCreateCustomForm'
import TaskMixin from '@/mixins/TaskMixin'
import AreaDetails from '@/components/map/AreaDetails'
import EventTab    from '@/components/events/EventList.vue'
import { taskUpdateService, eventUpdateService } from '@/rxjsServices';

export default {
  mixins: [TaskMixin],
  components: {
    AreaDetails,
    TasksList,
    TaskCreateForm,
    TaskCreateCustomForm,
    EventTab
  },
  props: {
    area: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      areaTasks: [],
      tab: 0,
      open: false
    }
  },
  watch: {
    area: {
      async handler() {
        await this.loadData()
        eventUpdateService.sendEvent('update')
      },
      immediate: true
    }
  },
  async mounted () {
    this.taskService = taskUpdateService.getTask().subscribe(message => {
      if (message) {
        this.loadData()
      }
    })
  },
  methods: {
    show () {
      this.open = true
    },
    close () {
      this.open = false
    },
    onCreate () {
      this.$emit('added')
      this.loadData()
    },
    async loadData () {
      try {
        this.loading = true
        this.areaTasks = await this.fetchTasksByArea(this.area.are_id)
      } catch (err) {
        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: err })
      } finally {
        this.loading = false
      }
    },
    beforeDestroy() {
      this.taskService.unsubscribe()
    }
  }
}
</script>
